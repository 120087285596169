import React from 'react';
import {
  alpha,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);


export default React.forwardRef(({ field, initialValue = false, setValue = null, onChange = null}, ref) => {
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [value, setValueState] = React.useState(initialValue);

  React.useEffect(() => {
    setValueState(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    const newValue = event.target.checked;
    setValueState(newValue);
    if (setValue) setValue(newValue);
    if (onChange) onChange(newValue);
  };

  function validate() {
    const valid = field.validation(value);
    if (valid === true) return true;
    
    setErrorMessage(valid);
    setError(true);
    return false;
  }

  React.useImperativeHandle(ref, () => ({ validate }));

  return (
    <FormControl row fullWidth error={error}>
      <FormControlLabel
        fullWidth
        labelPlacement="end"
        control={
          <Checkbox
            fullWidth
            checked={value}
            onChange={e => handleChange(e)}
            name={field.name}
            helperText={field.invalidMessage}
            input={
              <BootstrapInput />
            }
            color="primary"
          />
        }
        label={field.label}
      />
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
});
