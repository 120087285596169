import React from 'react';
import {
  alpha,
  makeStyles,
  withStyles,
  useTheme
} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';


const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(choiceKey, chioces, theme) {
  return {
    fontWeight:
      chioces.indexOf(choiceKey) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default React.forwardRef(({ field, initialValue = [], setValue = null, onChange = null}, ref) => {
  const theme = useTheme();
  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [values, setValueState] = React.useState(initialValue);

  React.useEffect(() => {
    setValueState(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValueState(newValue);
    if (setValue) setValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleFocus = (event) => {
    setError(false);
  };

  function validate() {
    const valid = field.validation(values);
    if (valid === true) return true;
    
    setErrorMessage(valid);
    setError(true);
    return false;
  }

  const getLabelFromKey = key => {
    const item = field.choices?.find(obj => obj.key === key);
    return item ? item.value : null;
  };

  React.useImperativeHandle(ref, () => ({ validate }));

  return (
    <FormControl
      className={classes.margin}
      fullWidth
      error={error}
      required={field.isRequired}
    >
      <InputLabel shrink htmlFor={field.name}>
        {field.label}
      </InputLabel>
      <Select
        fullWidth
        id={field.name}
        required={field.isRequired}
        helperText={field.invalidMessage}
        multiple
        value={values}
        onChange={e => handleChange(e)}
        onFocus={handleFocus}
        input={
          <BootstrapInput 
            id={`select-multiple-${field.name}`}
            placeholder={field.placeHolderText}
          />
        }
        renderValue={(selected = []) => (
          <div className={classes.chips}>
            {selected?.map((key) => (
              <Chip
                key={key}
                label={getLabelFromKey(key)}
                className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        <MenuItem disabled value="">
          <em>{field.placeHolderText}</em>
        </MenuItem>
        {field.choices?.map((choice) => (
          <MenuItem
            key={choice.key}
            value={choice.key}
            style={getStyles(choice.key, values, theme)}>
            {choice.value}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
});
