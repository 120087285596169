import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Layout from '../../components/v2/Layout';
import Button from '../../components/v2/Button';
import DynamicForm from '../../components/DynamicForm';

import { 
  WarningRounded as WarningRoundedIcon,
} from '@material-ui/icons';
import { employeeHealthRegister } from '../../actions/partners';

const useStyles = makeStyles((theme) => ({
  
  registerContainer: {
    width: '100%',
    minHeight: '560px',
    borderRadius: '33px',
    boxShadow: '0px 5px 35px rgba(0, 0, 0, 0.25)',
    background: 'linear-gradient(135deg, rgba(255,255,255,0.7) 0%,  rgba(255,255,255,0.7) 18%,  rgba(208,241,255,0.7) 145%)',
    display:'flex',
    flexDirection: 'column',
    justifyContent:'center',
    animation: '$card-show 0.75s ease 1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '512px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      borderRadius: '25px',
    },
    [theme.breakpoints.down(350)]: {
      minWidth: '100%',
      borderRadius: '20px',
    },
  },
  cardPermissionDenied: {
    width: '100%',
    minHeight: '450px',
    borderRadius: '33px',
    boxShadow: '0px 5px 35px rgba(0, 0, 0, 0.25)',
    background: 'linear-gradient(135deg, rgba(255,255,255,0.7) 0%,  rgba(255,255,255,0.7) 18%,  rgba(208,241,255,0.7) 145%)',
    display:'flex',
    flexDirection: 'column',
    justifyContent:'center',
    animation: '$card-show 0.75s ease 1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '450px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      borderRadius: '25px',
    },
    [theme.breakpoints.down(350)]: {
      minWidth: '100%',
      borderRadius: '20px',
    },
  },
  permissionContainer: {
    position: 'relative',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    marginBottom: '15px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    },
  },
  cardContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '25px 0 25px 0',
  },
  flexCenter:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  titleContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '35px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    },
  },
  textCompany: {
    fontFamily: 'Prompt',
    fontSize: '35px',
    fontWeight: 500,
    textAlign: 'left',
    lineHeight: 1.3,
    [theme.breakpoints.down('xs')]: {
      fontSize: '33px', 
    },
    [theme.breakpoints.down(500)]: {
      fontSize: '29px', 
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '22px', 
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '20px', 
    },
  },
  uppertitleContainer:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems:'center',
  },
  categorySign:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems:'center',
    minWidth: '95px', height: '25px',
    borderRadius: '15px',
    backgroundColor: 'rgb(37,55,69)',
    padding:'0 15px 0 5px',
    [theme.breakpoints.down(500)]: {
      height: '15px',
      justifyContent: 'left',
      minWidth: '50px',
      padding:'0 7px 0 5px',
    },
  },
  categorySignHandle:{
    backgroundColor: 'rgb(255,255,255)',
    width: '15px', height: '15px',
    borderRadius: '15px',
    marginRight: '5px',
    [theme.breakpoints.down(500)]: {
      width: '10px', height: '10px',
      marginRight: '5px',
    },
  },
  categorySignText:{
    fontFamily: 'Prompt',
    fontSize: '10px',
    fontWeight: 600,
    color: 'rgb(255,255,255)',
    [theme.breakpoints.down(500)]: {
      fontSize: '8px',
    },
  },
  subtitleContainer:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop:'-8px',
    height:'25px',
  },
  subtitleText:{
    fontFamily: 'Prompt',
    fontSize: '12px',
    fontWeight: 500,
    color: 'rgb(135,135,135)',
    [theme.breakpoints.down(500)]: {
      fontSize: '10px',
    },
  },
  logoImg:{
    width: 'auto',
    height: '25px',
    objectFit: 'cover',
    [theme.breakpoints.down(500)]: {
      height: '20px',
    },
  },
  inputContainer:{
    position: 'relative',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    marginBottom: '15px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    },
  },
  checkBoxContainer:{
    position: 'relative',
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    alignItems: 'center',
    marginBottom: '15px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    },
  },
  labelTextField: {
    fontFamily: 'Prompt',
    fontSize: '14px',
    fontWeight: 500,
    width: '80%',
    textAlign: 'left',
    [theme.breakpoints.down(500)]: {
      fontSize: '12px',
    },
  },
  inputTextField:{
    fontFamily: 'Prompt',
    fontSize: '14px',
    fontWeight: 400,
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: '8px',
    [theme.breakpoints.down(500)]: {
      fontSize: '12px',
    },
  },
  helperTextShow: {
    fontFamily: 'Prompt',
    fontSize: '12px',
    fontWeight: 400,
    height: '25px',
    [theme.breakpoints.down(500)]: {
      fontSize: '10px',
    },
    animation: '$helper-text-show 0.5s ease 1',
    animationFillMode: 'forwards',
  },
  helperTextDisappear: {
    fontFamily: 'Prompt',
    fontSize: '12px',
    fontWeight: 400,
    height: '0px',
    transition: 'all 0.5s ease',
    [theme.breakpoints.down(500)]: {
      fontSize: '10px',
    },
    animation: '$helper-text-disappear 0.5s ease 1',
    animationFillMode: 'forwards',
  },
  NoPermissionImg: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    animation: '$card-show 1s ease 1',
  },
  verifyButton: {
    width: '80%',
    fontFamily: 'Prompt',
    textTransform: 'capitalize',
    fontWeight: 600,
    transition: 'all 0.5s ease , box-shadow 0.5s ease',
    color: 'rgb(255,255,255)',
    boxShadow: 'inset 0 0 0 0 rgba(70,150,251,1)',
    background: 'linear-gradient(90deg, rgba(35,170,251,1) 33%, rgba(63,182,253,1) 55%, rgba(36,140,203,1) 100%)',
    [theme.breakpoints.down(500)]: {
      width: '90%',
      fontSize: '15px', 
    },
    '&:hover': {
      scale: 1.025,
      boxShadow: 'inset 700px 0 0 0 rgba(70,150,251,1)',
    
    }
  },
  verifyButtonDisable:{
    width: '80%',
    fontFamily: 'Prompt',
    textTransform: 'capitalize',
    fontWeight: 600,
    transition: 'all 0.5s ease',
    color: 'rgb(184,184,184)',
    background: 'linear-gradient(90deg, rgba(223,226,227,1) 33%, rgba(223,223,223,1) 55%, rgba(210,210,210,1) 100%)',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    }
  },
  verifyButtonText:{
    fontSize: '18px',
    [theme.breakpoints.down(500)]: {
      fontSize: '15px',
    },
  },
  errorMessage:{
    fontFamily: 'Prompt',
    fontSize: '14px',
    fontWeight: 500,
    color: '#f44336',
    width: '80%',
    minHeight: '22px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    marginBottom: '10px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
      fontSize: '12px',
      lineHeight: 1.1,
    },
    [theme.breakpoints.down(350)]: {
      fontSize: '10px',
    },
  },  
  titleTextPermission:{
    fontSize:'45px', 
    fontFamily:'QuickSand', 
    fontWeight:700,
    margin: '15px 0 10px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '45px', 
    },
    [theme.breakpoints.down(500)]: {
      fontSize: '42px', 
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '38px', 
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '35px', 
    },
  },
  textPermissionThai:{
    fontFamily: 'Prompt',
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: 1.3,
    margin: '5px 0 10px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px', 
    },
    [theme.breakpoints.down(500)]: {
      fontSize: '18px', 
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '16px', 
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '14px', 
    },
  },
  textPermissionEng:{
    fontFamily: "QuickSand",
    fontSize: '25px',
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: 1.1,
    margin: '10px 0 5px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px', 
    },
    [theme.breakpoints.down(500)]: {
      fontSize: '22px', 
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '20px', 
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '18px', 
    },
  },
  errorMessageIcon:{
    marginRight: '5px'
  },
  checkBox:{
    '&.MuiSvgIcon-root': { 
      fontSize: '50px',
    },
    '&.Mui-checked': {
      color: "rgba(35,170,251,1)",
    },
  },
  checkBoxLabel:{
    fontFamily: 'Prompt',
    fontSize: '15px',
    color: 'black'
  },
  '@keyframes card-show': {
    '0%': {
      scale: '0.5',
      opacity: '0.0',
    },
    '100%': {
      scale: '1.0',
      opacity: '1.0',
    },
  },
  '@keyframes helper-text-show': {
    '0%': {
      height: '0px',
      opacity: '0.0',
    },
    '100%': {
      height: '25px',
      opacity: '1.0',
    },
  },
  '@keyframes helper-text-disappear': {
    '0%': {
      height: '25px',
      opacity: '1.0',
    },
    '100%': {
      height: '0px',
      opacity: '0.0',
    },
  },
  
}));


export default ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { prevPage, registerFields, corporate } = useSelector(state => state.partners); 
  const [errorResponse, setErrorResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const content = {
    'header-p1': 'ลงทะเบียน',
    'company_name': corporate?.name? corporate.name:'Company' ,
    'category': 'for employee',
    'mor_online_logo': '../../assets/images/LogoMorwithLetter.png',
    'no_permission_img': '../../assets/images/NoPermission.png',
    'no_permission_desc_en': "you don't have permission to access this page.",
    'no_permission_desc_th': "ขออภัยคุณไม่มีสิทธิเข้าถึงหน้านี้"
  };

  const handleOnSave = async (payload) =>{
    setLoading(true);
    setErrorResponse(null);
    try {

      await dispatch(employeeHealthRegister(payload));
      navigate(prevPage);
    }
    catch(e) { 
      setErrorResponse(e);
      setLoading(false);
    }
  }

  const handleGoBack = () =>{
    navigate('/')
  }

  return (
    <Layout
      bg="bg5"
      title={content['header-p1']}
    >
      <div className={classes.flexCenter}>
        <Grid container spacing={0} 
          justifyContent='center' 
          alignItems='center'
        >
          
          <Grid item xs={12} sm={5} md={5} xl={5} className={classes.flexCenter} >

            {registerFields ? 
              <Card variant="outlined" className={classes.registerContainer}>
                  <CardContent style={{height:'100%'}}>
                    <Box textAlign="center" className={classes.cardContent}>
                      <div className={classes.titleContainer}>
                        <div className={classes.uppertitleContainer}>
                          <div className={classes.categorySign}>
                            <div className={classes.categorySignHandle}></div>
                            <Typography className={classes.categorySignText} variant='h6' component='h1' color='textPrimary'>
                              {content['category']}
                            </Typography>
                          </div>
                        </div>
                        <Typography className={classes.textCompany} variant='h6' component='h1' color='textPrimary'>
                          {content['company_name']}
                        </Typography>
                        <div className={classes.subtitleContainer}>
                          <p className={classes.subtitleText}>powered by </p>
                          <img className={classes.logoImg} src={require(`${'../../assets/images/LogoMorwithLetter.png'}`)} alt='' />
                        </div>
                      </div>

                        <DynamicForm 
                          fieldData={registerFields}
                          loading={loading}
                          onSave={handleOnSave} />
                      
                      {
                        errorResponse != null?
                        <div className={classes.errorMessage}>
                          <WarningRoundedIcon className={classes.errorMessageIcon}/>
                            {errorResponse.message.detail} 
                        </div>
                        : <div className={classes.errorMessage}></div>
                      }
                      {/* <Button 
                        className={ 
                          enableSubmit 
                          ? classes.verifyButtonDisable 
                          : classes.verifyButton}
                        disabled={enableSubmit}
                        onClick={()=>handleVerify()}
                      >
                          <span className={classes.verifyButtonText}>{'ส่งข้อมูล'}</span> 
                      </Button> */}
                      
                    </Box>
                  </CardContent>
              </Card>
              : 
              <Card variant="outlined" className={classes.cardLogin}>
                <CardContent>
                  <Box textAlign="center" className={classes.cardContent}>
                    <div className={classes.permissionContainer}>
                      <h1 className={classes.titleTextPermission}>
                        {"Sorry :("}
                      </h1>
                      <img className={classes.NoPermissionImg} src={require(`${'../../assets/images/NoPermission.png'}`)} alt='' />
                      <Typography className={classes.textPermissionEng} variant='h6' component='h1' color='textPrimary'>
                            {content['no_permission_desc_en']}
                      </Typography>
                      <Typography className={classes.textPermissionThai} variant='h6' component='p' color='textPrimary'>
                            {content['no_permission_desc_th']}
                      </Typography>
                    </div>

                    <Button 
                        className={ classes.verifyButton}
                        onClick={()=>handleGoBack()}
                      >
                        <span className={classes.verifyButtonText}>{'กลับไปหน้าแรก'}</span> 
                    </Button>

                  </Box>
                </CardContent>
              </Card> 
            }
          </Grid>
              
        </Grid>
      </div>
    </Layout>
  );
}