import React from 'react';
import {
  alpha,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));


export default React.forwardRef(({ field, initialValue = '', setValue = null, onChange = null}, ref) => {
  const classes = useStyles();
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [value, setValueState] = React.useState(initialValue);

  React.useEffect(() => {
    setValueState(initialValue);
  }, [initialValue]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValueState(newValue);
    if (setValue) setValue(newValue);
    if (onChange) onChange(newValue);
  };

  const handleFocus = (event) => {
    setError(false);
  };

  function validate() {
    const valid = field.validation(value);
    if (valid === true) return true;
    
    setErrorMessage(valid)
    setError(true);
    return false;
  }

  React.useImperativeHandle(ref, () => ({ validate }));

  return (
    <FormControl
      className={classes.margin}
      fullWidth
      error={error}
      required={field.isRequired}
    >
      <InputLabel shrink htmlFor={field.name}>
        {field.label}
      </InputLabel>
      <BootstrapInput
        fullWidth
        required={field.isRequired}
        id={field.name}
        name={field.name}
        label={field.label}
        value={value}
        variant="outlined"
        type="email"
        helperText={field.invalidMessage}
        placeholder={field.placeHolderText}
        onChange={e => handleChange(e)}
        onFocus={handleFocus}
      />
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
});
