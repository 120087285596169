import React from 'react';

import { 
  PersonRounded as PersonRoundedIcon,
  LockRounded as LockRoundedIcon,
  Phone as PhoneIcon,
  BrandingWatermarkRounded as IdIcon,
} from '@material-ui/icons';
import {
  alpha,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

import Button from '../../components/v2/Button';
import DateField from './DateField';
import EmailField from './EmailField';
import PasswordField from './PasswordField';
import IntegerField from './IntegerField';
import MultipleSelectField from './MultipleSelectField';
import PhoneField from './PhoneField';
import SingleSelectField from './SingleSelectField';
import StringField from './StringField';
import SwitchField from './SwitchField';

const useStyles = makeStyles((theme) => ({
  verifyButton: {
    fontFamily: 'Prompt',
    textTransform: 'capitalize',
    fontWeight: 600,
    marginTop: '18px',
    transition: 'all 0.5s ease , box-shadow 0.5s ease',
    color: 'rgb(255,255,255)',
    boxShadow: 'inset 0 0 0 0 rgba(70,150,251,1)',
    background: 'linear-gradient(90deg, rgba(35,170,251,1) 33%, rgba(63,182,253,1) 55%, rgba(36,140,203,1) 100%)',
    [theme.breakpoints.down(500)]: {
      width: '100%',
      fontSize: '15px', 
    },
    '&:hover': {
      scale: 1.025,
      boxShadow: 'inset 700px 0 0 0 rgba(70,150,251,1)',
    
    }
  },

  verifyButtonDisable:{
    fontFamily: 'Prompt',
    textTransform: 'capitalize',
    fontWeight: 600,
    marginTop: '18px',
    transition: 'all 0.5s ease',
    color: 'rgb(184,184,184)',
    background: 'linear-gradient(90deg, rgba(223,226,227,1) 33%, rgba(223,223,223,1) 55%, rgba(210,210,210,1) 100%)',
    [theme.breakpoints.down(500)]: {
      width: '100%', 
    }
  },

  verifyButtonText:{
    fontSize: '18px',
    [theme.breakpoints.down(500)]: {
      fontSize: '15px',
    },
  },

  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    width: '100%'
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  
}));

export default ({ fieldData, onSave = null, loading = false }) => {
  const classes = useStyles();
  const fieldRefs = React.useRef({});
  const [isPassVerify, setIsPassVerify] = React.useState(true);
  const [formData, setFormData] = React.useState(() => {
    const formData = {};
    fieldData.forEach(field => {
      switch (field.type) {
        case 'email':
        case 'string':
        case 'phone':
        case 'password':
            formData[field.name] = '';
            break;
        case 'date':
        case 'number':
        case 'single_choice':
            formData[field.name] = null;
            break;
        case 'boolean':
            formData[field.name] = false;
            break;
        case 'multiple_choice':
            formData[field.name] = [];
            break;
        default:
          formData[field.name] = '';
            break;
      }
    });
    return formData;
  });
  
  const handleFieldValue = (name, value) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const cleanForm = formData => {
    return formData;
  };

  function onSubmit(event) {
    event.preventDefault();
    let isValid = true;

    Object.keys(fieldRefs.current).forEach(key => {
      if (!fieldRefs.current[key].current.validate()) {
        isValid = false;
      }
    });

    if (isValid) onSave(cleanForm(formData));
  }

  const renderField = (field) => {
    if (!fieldRefs.current[field.name]) {
      fieldRefs.current[field.name] = React.createRef();
    }

    switch (field.type) {

      case 'date':
        return (
          <DateField
            key={field.name}
            ref={fieldRefs.current[field.name]}
            field={field}
            value={formData[field.name]}
            onChange={value => handleFieldValue(field.name, value)}
          />
        )

      case 'email':
        return (
          <EmailField
            key={field.name}
            ref={fieldRefs.current[field.name]}
            field={field}
            value={formData[field.name]}
            onChange={value => handleFieldValue(field.name, value)}
          />
        )

      case 'string':
        return (
          <StringField
            key={field.name}
            ref={fieldRefs.current[field.name]}
            field={field}
            value={formData[field.name]}
            onChange={value => handleFieldValue(field.name, value)}
          />
        )
      
      case 'number':
        return (
          <IntegerField
            key={field.name}
            ref={fieldRefs.current[field.name]}
            field={field}
            value={formData[field.name]}
            onChange={value => handleFieldValue(field.name, value)}
          />
        )
        
      case 'phone':
        return (
          <PhoneField
            key={field.name}
            ref={fieldRefs.current[field.name]}
            field={field}
            value={formData[field.name]}
            onChange={value => handleFieldValue(field.name, value)}
          />
        )
      
      case 'boolean':
        return (
          <SwitchField
            key={field.name}
            ref={fieldRefs.current[field.name]}
            field={field}
            value={formData[field.name]}
            onChange={value => handleFieldValue(field.name, value)}
          />
        )
      
      case 'password':
        return (
          <PasswordField
            key={field.name}
            ref={fieldRefs.current[field.name]}
            field={field}
            value={formData[field.name]}
            onChange={value => handleFieldValue(field.name, value)}
          />
        )
      
      case 'single_choice':
        return (
          <SingleSelectField
            key={field.name}
            ref={fieldRefs.current[field.name]}
            field={field}
            value={formData[field.name]}
            onChange={value => handleFieldValue(field.name, value)}
          />
        )
      
      case 'multiple_choice':
        return (
          <MultipleSelectField
            key={field.name}
            ref={fieldRefs.current[field.name]}
            field={field}
            initialValue={formData[field.name]}
            onChange={value => handleFieldValue(field.name, value)}
          />
        )

      default:
        return <div>Unsupported field type</div>;
    }
  };

  const fields = fieldData.map(field => {
      let data = {};
      const placeHolderText = field.placeholder
        ? field.placeholder
        : `กรุณากรอก${field.label}ของคุณ`;
      const invalidMessage = 'โปรดใส่ค่าที่ถูกต้อง';
  
      switch (field.type) {
        case 'date':
          data = {
            icon: <IdIcon />,
          }
          break;

        case 'email':
          data = {
            icon: <PersonRoundedIcon />,
          }
          break;

        case 'string':
          data = {
            icon: <IdIcon />,
          }
          break;

        case 'number':
          data = {
            icon: <IdIcon />,
          }
          break;

        case 'phone':
          data = {
            icon: <PhoneIcon />,
          }
          break;
        case 'boolean':
          data = {
            icon: <PhoneIcon />,
          }
          break;
        case 'password':
          data = {
            icon: <LockRoundedIcon />,
          }
          break;
        case 'single_choice':
          data = {
            icon: <IdIcon />,
          }
          break;
        case 'multiple_choice':
          data = {
            icon: <IdIcon />,
          }
          break;
      }
  
      return {
        ...data,
        ...field,
        placeHolderText: placeHolderText,
        invalidMessage: invalidMessage,
        isValide: false,
        isTouch: false,
        isShow: true,
      };
    });

  return (
    <form noValidate autoComplete="off" style={{display: "contents"}}>
      {fields.map(field => renderField(field))}
      <div className={classes.wrapper}>
        <Button
          className={ 
            !isPassVerify || loading 
            ? classes.verifyButtonDisable 
            : classes.verifyButton}
          disabled={!isPassVerify || loading }
          onClick={(e) => onSubmit(e)}
        >
          <span className={classes.verifyButtonText}>{'ส่งข้อมูล'}</span> 
        </Button>
        {
          loading &&
          <CircularProgress
            size={24}
            className={classes.buttonProgress} />
        }
      </div>
    </form>
  );
}
